import React from "react"
import PropTypes from "prop-types"
import Layout from "./layout"
import SEO from "./seo"
import { StaticImage } from "gatsby-plugin-image"

const Learn = ({ ctaSection }) => {
    return (
        <Layout>
            <SEO title="Learn" />
            <div>
                <div
                        className="h-[24px] rounded-t-none  sm:rounded-t-[15px]
                        rounded-t-[20px]
                        accent-gradient-bg
                        w-full"
                />
                {/* Header */}
                <div className="w-full flex flex-col items-center justify-center rounded-b-[15px] pt-[32px] lg:pt-[48px] px-[16px] lg:px-0 gap-[36px] lg:gap-[48px] mb-[64px] hero-bg relative overflow-hidden">
                    <div style={{ maxWidth: `440px` }} className="lg:absolute lg:block top-[-50px] right-[-50px] hidden">
                        <StaticImage
                            src="../images/vector.png"
                            alt="vector icon"
                            className=""
                        />
                    </div>
                    <div style={{ maxWidth: `175px`, marginBottom: `1.45rem` }}>
                        <StaticImage
                            src="../images/leo_logo.png"
                            alt="Leonardo.AI logo"
                        />
                    </div>
                    <div className="flex gap-[96px] justify-center flex-col lg:flex-row">
                        <div className="flex flex-col items-start gap-[36px] w-full max-w-[568px] relative">
                            <div style={{ maxWidth: `380px` }} className="lg:absolute lg:block hidden bottom-[200px] z-0">
                                <StaticImage
                                    src="../images/ellipse.png"
                                    alt="bg ellipse"
                                    className=""
                                />
                            </div>
                            <p class="w-fit flex justify-center items-center gap-[9px] p-[16px_20px] rounded-[8px] z-[1]
                            text-[#892FE0] font-semibold
                            bg-white shadow-[0px_1px_2px_0px_rgba(0,180,169,0.06),_0px_1px_3px_0px_rgba(0,180,169,0.10)]">
                                Like Duolingo, but you decide what you want to learn 💡
                            </p>
                            <h1 class="text-[#141414] text-[42px] font-semibold leading-[54.6px] tracking-[-0.84px] z-[1]">Daily bitesize lessons about{' '}
                                <span class="bg-[conic-gradient(from_199deg_at_46.09%_53.52%,_#842ED8_111.46745681762695deg,_#DB28A9_212.01815128326416deg,_#9D1DCA_360deg)] bg-clip-text text-transparent font-[General_Sans] text-[42px] italic font-semibold leading-[130%] tracking-[-0.84px] pr-[5px]">anything you want!</span>
                            </h1>
                            <p class="text-[#141414] text-[24px] font-medium leading-[150%] z-[1]">Upskill for work, impress your crush, indulge your Roman Empire!</p>
                            {ctaSection}
                        </div>
                        <div>
                            <div style={{ maxWidth: `450px` }}>
                                <StaticImage
                                    src="../images/hand.png"
                                    alt="Leonardo.AI mockup"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* users like */}
                <div className="w-full flex flex-col items-center justify-center rounded-[15px] pt-[48px] pb-[0px] lg:py-[48px] px-[16px] lg:px-0 gap-[48px] mb-[64px] bg-[rgba(226,234,245,0.2)]">
                    <div className="flex flex-col items-center justify-center gap-[16px]">
                        <div className="w-fit text-[18px] font-bold p-[12px] rounded-[4px] text-[#892FE0]
                                        pill-bg pill-shadow">
                            What you'll learn
                        </div>
                        <h2 className="text-[#101828] text-[36px] font-semibold leading-[130%] tracking-[-0.72px]">
                            Our users are learning things like...
                        </h2>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row items-center gap-[48px] lg:gap-[96px]">
                        <div class="relative lg:static lg:left-0" style={{ maxWidth: `500px`, left: '-10px' }}>
                            <StaticImage
                                src="../images/girl.png"
                                alt="Leonardo.AI mockup"
                            />
                        </div>
                        <div>
                            <p className="text-[20px] text-[#141414] pb-[16px]">"Investing 101s for a busy professional"</p>
                            <p className="text-[20px] text-[#141414] pb-[16px]">"United Stated Presidential Election system"</p>
                            <p className="text-[20px] text-[#141414] pb-[16px]">"Digital marketing concepts for a sales person"</p>
                            <p className="text-[20px] text-[#141414] pb-[16px]">"Ancient Egyptian history"</p>
                            <p className="text-[20px] text-[#141414] pb-[32px]">"How does AI actually work?"</p>
                            <p className="text-[20px] text-[#141414] font-semibold">But you don't have to, you should do you 😉</p>
                        </div>
                    </div>
                </div>

                {/* how it works */}
                <div className="w-full flex flex-col items-center justify-center rounded-[15px] px-[16px] lg:px-0 py-[48px] gap-[48px] bg-[rgba(253,229,234,0.2)]">
                    <div className="flex flex-col items-center justify-center gap-[16px]">
                        <div className="w-fit text-[18px] font-bold p-[12px] rounded-[4px] text-[#892FE0]
                                        pill-bg pill-shadow">
                            How it works
                        </div>
                        <h2 className="text-[#101828] text-[36px] font-semibold leading-[130%] tracking-[-0.72px]">
                            Just keep your streak and grow your skills ⚡️
                        </h2>
                    </div>
                    <div className="flex flex-col-reverse lg:flex-row items-center gap-[48px] lg:gap-[96px]">
                        <div class="relative lg:static lg:left-0" style={{ maxWidth: `400px`, left: '-20px' }}>
                            <StaticImage
                                src="../images/13.png"
                                alt="Leonardo.AI mockup"
                            />
                        </div>
                        <div>
                            <div className="flex items-center gap-[24px] pb-[16px]">
                                <span class="inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">1</span>
                                <p className="text-[20px] text-[#141414]">Tell our AI what you want to learn about and for how long</p>
                            </div>
                            <div className="flex items-center gap-[24px] pb-[16px]">
                            <span class="inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">2</span>
                                <p className="text-[20px] text-[#141414]">We will generate a fully personalised learning plan</p>
                            </div>
                            <div className="flex items-center gap-[24px] pb-[16px]">
                            <span class="inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">3</span>
                                <p className="text-[20px] text-[#141414]">Complete daily 5-min lessons on your topic</p>
                            </div>
                            <div className="flex items-center gap-[24px] pb-[16px]">
                            <span class="inline-flex items-center justify-center w-10 h-10 bg-[#F2F4F7] text-[#000] text-[20px] font-medium rounded-full">4</span>
                                <p className="text-[20px] text-[#141414]">Learn, practice, repeat</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
    
}

Learn.propTypes = {
  ctaSection: PropTypes.node.isRequired,
}

export default Learn
